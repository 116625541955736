import React from "react"
import { Link } from "gatsby"

const Header = () => (
  <div className="py-3">
    <div className="h-30 sm:h-30 flex flex-col items-center">
      <h2 className="mx-auto text-center text-3xl sm:text-4xl text-gray-900 allura">
        <Link to="/">
          Sweet Scarlet Blooms Photography
        </Link>
      </h2>
      <p style={{ marginTop: -10}} className="text-3xl allura">
        by Patricia Linke
      </p>
    </div>
    <div className="flex flex-row sm:justify-center justify-evenly border-gray-300 mt-4">
      <Link className="w-1/6 py-2 text-center hover:bg-gray-200" to="/"><div>HOME</div></Link>
      <Link className="w-1/6 py-2 text-center hover:bg-gray-200" to="/gallery"><div>GALLERY</div></Link>
      <Link className="w-1/6 py-2 text-center hover:bg-gray-200" to="https://docs.google.com/forms/d/e/1FAIpQLSeuV6BurE8SUKqfG7Ux7oVlGy1ClQOd77ewGYp-IixZEtxLdg/viewform"><div>FALL MINI SESSION</div></Link>
      <Link className="w-1/6 py-2 text-center hover:bg-gray-200" to="/contact"><div>CONTACT</div></Link>
    </div>
  </div>
)

export default Header

/*
<Link className="w-1/6 py-2 text-center hover:bg-gray-200" to="/about"><div>ABOUT ME</div></Link>
<Link className="w-1/6 py-2 text-center hover:bg-gray-200" to="/investment"><div>INVESTMENT</div></Link>
*/