import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const Footer = () => {
  const {
    site: {
      meta: { title },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          title
        }
      }
    }
  `)

  return (

      <p style={{ fontSize: 12 }} className="text-center w-3/4 italic my-4 mx-auto text-gray-700">
        &copy; 2023 {title}. All rights reserved. All images and content on this website
        are the works of {title} and are protected under copyright laws. It is unlawful
        to copy or use any images or content in any way without the written permission
        of {title}.
      </p>

  )
}

export default Footer
